<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loading">
                <b-row>
                  <b-col>
                    <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail"
                      :org-id="orgiId">
                      <div class="titleDiv">
                      </div>
                    </list-report-head>
                  </b-col>
                </b-row>
                <b-col lg="12">
                  <b-row>
                    <div class="card card w-100 text-center">
                      <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                        <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light"
                          style="color: green!important;font-size: 20px!important;"> {{ $t('elearning_iabm.trainee_registration')+
                          ' ' + this.$t('globalTrans.details') }}</h5>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12" class="p-0">
                      <b-table-simple bordered striped>
                        <b-thead>
                          <b-tr v-if="profile.image">
                            <!-- <b-th style="width:40%" rowspan="2"><img :src="trainingElearningServiceBaseUrl + 'storage/uploads/persional-info/' + profile.image" style="width: 250px; height: 200px; border-radius: 0px !important;" class="center"></b-th> -->
                            <b-th style="width:40%" colspan="2">
                              <div class="text-center"><img
                                  :src="trainingElearningServiceBaseUrl + 'storage/' + profile.image"
                                  style="width: 130px; height: 120px; border-radius: 0px !important;" class="center">
                              </div>
                            </b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-th style="" class="w-50 line-height-five" scope="row">{{ $t('user.namel') }}</b-th>
                            <b-td style="" class="w-50 line-height-five">{{ ($i18n.locale=='bn') ? profile.name_bn :
                              profile.name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.name_of_father_husband_bn || profile.name_of_father_husband">
                            <b-th style="" class="w-50 line-height-five" scope="row">{{ $t('externalUserIrrigation.father_name') }}</b-th>
                            <b-td style="" class="w-50 line-height-five">{{ ($i18n.locale=='bn') ? profile.name_of_father_husband_bn : profile.name_of_father_husband }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.mother_name_bn || profile.mother_name">
                            <b-th style="" class="w-50 line-height-five" scope="row">{{ $t('externalUserIrrigation.mother_name') }}</b-th>
                            <b-td style="" class="w-50 line-height-five">{{ ($i18n.locale=='bn') ? profile.mother_name_bn :
                              profile.mother_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.nid">
                            <b-th style="" scope="row">{{ $t('externalUserIrrigation.nid') }}</b-th>
                            <b-td style="">
                              <slot>
                                {{ $n(profile.nid, { useGrouping: false }) }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.nid_attachment">
                            <b-th style="" scope="row">{{ $t('elearning_iabm.nid_attach') }}</b-th>
                            <b-td style="">
                              <a target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/iab/personal_info/nid/' + profile.nid_attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('externalUserIrrigation.date_of_birth') }}</b-th>
                            <b-td style="">{{ profile.date_of_birth | dateFormat }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.gender') }}</b-th>
                            <b-td style="">
                              <slot v-if="profile.gender">
                                {{ ($i18n.locale=='bn') ? profile.gender_name_bn : profile.gender_name }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.mobile') }}</b-th>
                            <b-td style="">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.mobile, { useGrouping:
                              false }) }}
                            </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style=" " scope="row">{{ $t('externalTraining.email') }}</b-th>
                            <b-td style=" ">
                              <slot v-if="profile.email">
                                {{ ($i18n.locale=='bn') ? profile.email : profile.email }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.marital_status">
                            <b-th style=" " scope="row">{{ $t('externalTraining.mat_status') }}</b-th>
                            <b-td style=" ">
                              <slot>
                                {{ ($i18n.locale=='bn') ? profile.marital_name_bn : profile.marital_name }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.blood_group">
                            <b-th style="" scope="row">{{ $t('externalTraining.blood_group') }}</b-th>
                            <b-td style="">
                              <slot>
                                {{ ($i18n.locale=='bn') ? profile.blood_name_bn : profile.blood_name }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.religion">
                            <b-th style="" scope="row">{{ $t('externalTraining.religion') }}</b-th>
                            <b-td style="">
                              <slot>
                                {{ ($i18n.locale=='bn') ? profile.religion_name_bn : profile.religion_name }}
                              </slot>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                  <b-row>
                    <div class="card card w-100 text-center">
                      <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                        <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light"
                          style="color: green!important;font-size: 20px!important;"> {{
                          $t('elearning_iabm.emergency_contact_information') }}</h5>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12" class="p-0">
                      <b-table-simple bordered striped>
                        <b-thead>
                          <b-tr v-if="profile.emergency_name || profile.emergency_name_bn">
                            <b-th style="" class="w-50 line-height-five" scope="row">{{
                              $t('elearning_iabm.emergency_contact_person') }}</b-th>
                            <b-td style="" class="w-50 line-height-five">{{ ($i18n.locale=='bn') ?
                              profile.emergency_name_bn : profile.emergency_name }}</b-td>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-if="profile.emergency_relation || profile.emergency_relation_bn">
                            <b-th style="" class="w-50 line-height-five" scope="row">{{
                              $t('elearning_iabm.emergency_contact_relation') }}</b-th>
                            <b-td style="" class="w-50 line-height-five">{{ ($i18n.locale=='bn') ?
                              profile.emergency_relation_bn : profile.emergency_relation }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th class="w-50 line-height-five" scope="row">{{ $t('elearning_iabm.emergency_contact_number') }}</b-th>
                            <b-td class="w-50 line-height-five">
                              <slot v-if="profile.emergency_contact">
                                {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.emergency_contact, { useGrouping:
                                false }) }}
                              </slot>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                  <b-row>
                    <div class="card card w-100 text-center">
                      <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                        <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light"
                          style="color: green!important;font-size: 20px!important;"> {{
                          $t('externalTraining.present_address') }}</h5>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12" class="p-0">
                      <b-table-simple bordered striped>
                        <b-thead>
                          <b-tr>
                            <b-th style="" scope="col">{{ $t('org_pro.area_type') }}</b-th>
                            <b-th style="" scope="col">{{ ($i18n.locale=='bn') ? profile.area_name_bn : profile.area_name
                              }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-th style="" class="w-50" scope="row">{{ $t('org_pro_division.division') }}</b-th>
                            <b-td style="" class="w-50">{{ ($i18n.locale=='bn') ? profile.division_name_bn :
                              profile.division_name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('org_pro_district.district') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.district_name_bn : profile.district_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="(item.address.pre_area_type_id == 3||2) && item.address.pre_upazilla_id">
                            <b-th style="" scope="row">{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.upazilla_name_bn : profile.upazilla_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="item.address.pre_area_type_id == 3 && item.address.pre_union_id">
                            <b-th style="" scope="row">{{ $t('org_pro_union.union') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.union_name_bn : profile.union_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="item.address.pre_area_type_id == 1 && item.address.pre_city_corporation_id">
                            <b-th style="" scope="row">{{ $t('org_pro.city_corporation') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.cityCorporation_name_bn :
                              profile.cityCorporation_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="(item.address.pre_area_type_id == 3||2) && item.address.pre_ward_id">
                            <b-th style="" scope="row">{{ $t('org_pro.ward') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.ward_name_bn : profile.ward_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="item.address.pre_area_type_id == 2 && item.address.pre_pauroshoba_id">
                            <b-th style="" scope="row">{{ $t('org_pro.pauroshoba') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.pauroshoba_name_bn :
                              profile.pauroshoba_name }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                  <b-row>
                    <div class="card card w-100 text-center">
                      <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                        <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light"
                          style="color: green!important;font-size: 20px!important;"> {{
                          $t('externalTraining.parmanent_address') }}</h5>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12" class="p-0">
                      <b-table-simple bordered striped>
                        <b-thead>
                          <b-tr>
                            <b-th style="" scope="col">{{ $t('org_pro.area_type') }}</b-th>
                            <b-td style="" scope="col">{{ ($i18n.locale=='bn') ? profile.per_area_name_bn :
                              profile.per_area_name }}</b-td>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-th style="" class="w-50" scope="row">{{ $t('org_pro_division.division') }}</b-th>
                            <b-td style="" class="w-50">{{ ($i18n.locale=='bn') ? profile.per_division_name_bn :
                              profile.per_division_name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('org_pro_district.district') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_district_name_bn :
                              profile.per_district_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="(item.address.per_area_type_id == 3||2) && item.address.per_upazilla_id">
                            <b-th style="" scope="row">{{ $t('org_pro_upazilla.upazilla')}} </b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_upazilla_name_bn :
                              profile.per_upazilla_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="item.address.per_area_type_id == 3 && item.address.per_union_id">
                            <b-th style="" scope="row">{{ $t('org_pro_union.union') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_union_name_bn : profile.per_union_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="item.address.per_area_type_id == 1 && item.address.per_city_corporation_id">
                            <b-th style="" scope="row">{{ $t('org_pro.city_corporation') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_cityCorporation_name_bn :
                              profile.per_cityCorporation_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="(item.address.per_area_type_id == 3||2) && item.address.per_ward_id">
                            <b-th style="" scope="row">{{ $t('org_pro.ward') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_ward_name_bn : profile.per_ward_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="item.address.per_area_type_id == 2 && item.address.per_pauroshoba_id">
                            <b-th style="" scope="row">{{ $t('org_pro.pauroshoba') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_pauroshoba_name_bn :
                              profile.per_pauroshoba_name }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                  <b-row>
                    <div class="card card w-100 text-center">
                      <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                        <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light"
                          style="color: green!important;font-size: 20px!important;"> {{
                          $t('externalTraining.academic_info') }} </h5>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12" class="p-0">
                      <b-table-simple bordered striped>
                        <b-tbody>
                          <b-tr v-if="profile.name_of_highest_degree || profile.name_of_highest_degree_bn">
                            <b-th style="" class="w-50" scope="row">{{ $t('externalTraining.highest_degree') }}</b-th>
                            <b-td style="" class="w-50">
                              <slot>
                                {{ ($i18n.locale=='bn') ? profile.name_of_highest_degree_bn : profile.name_of_highest_degree }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.board || profile.board_bn">
                            <b-th style="" scope="row" class="w-50">{{ $t('externalTraining.board_university') }}</b-th>
                            <b-td style="" class="w-50">
                              <slot>
                                {{ ($i18n.locale=='bn') ? profile.board_bn : profile.board }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.passing_year">
                            <b-th style="" scope="row" class="w-50">{{ $t('elearning_iabm.passing_year') }}</b-th>
                            <b-td style="" class="w-50">
                              <slot>
                                {{ $n(profile.passing_year, { useGrouping: false }) }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.prof_group || profile.prof_group_bn">
                            <b-th style="" scope="row" class="w-50">{{ $t('elearning_iabm.group') }}</b-th>
                            <b-td style="" class="w-50">
                              <slot>
                                {{ ($i18n.locale=='bn') ? profile.prof_group_bn : profile.prof_group }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.cgpa">
                            <b-th style="" scope="row" class="w-50">{{ $t('elearning_iabm.cgpa') }}</b-th>
                            <b-td style="" class="w-50">
                              <slot>
                                {{ profile.cgpa }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.certificate_attachment">
                            <b-th style="" scope="row" class="w-50">{{ $t('globalTrans.attachment') }}</b-th>
                            <b-td style="" class="w-50">
                              <a target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/iab/personal_info/certificate/' + profile.certificate_attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                  <b-row>
                    <div class="card card w-100 text-center">
                      <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                        <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light"
                          style="color: green!important;font-size: 20px!important;"> {{
                          $t('externalTraining.professional_info') }}</h5>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <b-col lg="12" sm="12" class="p-0">
                      <b-table-simple bordered striped>
                        <b-tbody>
                          <b-tr>
                            <b-th class="w-50" style="" scope="col">{{ $t('elearning_iabm.profession_type') }}</b-th>
                            <b-td class="w-50" style="" scope="col">{{ ($i18n.locale=='bn') ? profile.proType_name_bn : profile.proType_name
                              }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th class="w-50" style="" scope="col">{{ $t('elearning_iabm.organization_or_office') }}</b-th>
                            <b-td class="w-50" style="" scope="col">
                              <slot v-if="profile.professional_info.profession_type == 2 || profile.professional_info.profession_type == 3">
                                {{ ($i18n.locale=='bn') ? profile.professional_info.other_org_name_bn : profile.professional_info.other_org_name }}
                              </slot>
                              <slot v-if="profile.professional_info.profession_type == 1">
                                {{ ($i18n.locale=='bn') ? profile.org_name_bn : profile.org_name }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1 || profile.professional_info.profession_type == 2" >
                            <b-th class="w-50" style="" scope="col">{{ $t('elearning_iabm.job_type') }}</b-th>
                            <b-td class="w-50" style="" scope="col">{{ ($i18n.locale=='bn') ? profile.job_name_bn : profile.job_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="(profile.professional_info.profession_type == 1 || profile.professional_info.profession_type == 2) && profile.professional_info.job_type_id == 1 && (profile.professional_info.cader_name || profile.professional_info.cader_name_bn)">
                            <b-th class="w-50" style="" scope="col">{{ $t('elearning_iabm.cader_name') }}</b-th>
                            <b-td class="w-50" style="" scope="col">{{ ($i18n.locale=='bn') ? profile.professional_info.cader_name_bn : profile.professional_info.cader_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="(profile.professional_info.profession_type == 1 || profile.professional_info.profession_type == 2) && profile.professional_info.job_type_id == 1 && profile.professional_info.batch">
                            <b-th class="w-50" style="" scope="row">{{ $t('elearning_iabm.bcs_batch') }}</b-th>
                            <b-td class="w-50" style="">
                              <slot v-if="profile.professional_info.batch">
                                {{ profile.professional_info.batch }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1 && profile.professional_info.office_type_id">
                            <b-th class="w-50" style="" scope="col">{{ $t('elearning_config.office_type') }}</b-th>
                            <b-td class="w-50" style="" scope="col">{{ ($i18n.locale=='bn') ? profile.office_type_name_bn : profile.office_type_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1 && profile.professional_info.not_here_office == 0">
                            <b-th class="w-50" style="" scope="col">{{ $t('globalTrans.office') }}</b-th>
                            <b-td class="w-50" style="" scope="col">{{ (($i18n.locale=='bn') ? profile.office_name_bn : profile.office_name) + ', ' + getOrgName(profile.professional_info.org_id)
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1 && profile.professional_info.not_here_office == 1">
                            <b-th class="w-50" style="" scope="col">{{ $t('globalTrans.office') }}</b-th>
                            <b-td class="w-50" style="" scope="col">{{ ($i18n.locale=='bn') ? profile.professional_info.office_name_bn : profile.professional_info.office_name
                              }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1 && (profile.professional_info.office_mail_address || profile.professional_info.office_mail_address_bn)">
                            <b-th class="w-50" style="" scope="row">{{ $t('externalTraining.office_mail') }}</b-th>
                            <b-td class="w-50" style="">
                              <slot>
                                {{ ($i18n.locale=='bn') ? profile.professional_info.office_mail_address_bn : profile.professional_info.office_mail_address }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 1 && profile.professional_info.designation_id" >
                            <b-th class="w-50" style="" scope="row">{{ $t('elearning_iabm.designation') }}</b-th>
                            <b-td class="w-50" style="">
                              <slot>
                                {{ ($i18n.locale=='bn') ? profile.designation_name_bn : profile.designation_name }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.profession_type == 2 || profile.professional_info.profession_type == 3 || profile.professional_info.not_here_designation == 1" >
                            <b-th class="w-50" style="" scope="row">{{ $t('elearning_iabm.designation') }}</b-th>
                            <b-td class="w-50" style="">
                              <slot>
                                {{ ($i18n.locale=='bn') ? profile.professional_info.designation_bn : profile.professional_info.designation_en}}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="(profile.professional_info.profession_type == 1 || profile.professional_info.profession_type == 2) && profile.professional_info.service_id">
                            <b-th class="w-50" style="" scope="row">{{ $t('externalTraining.service_id') }}</b-th>
                            <b-td class="w-50" style="">
                              <slot>
                                {{ $n(profile.professional_info.service_id, { useGrouping: false }) }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="(profile.professional_info.profession_type == 1 || profile.professional_info.profession_type == 2) && profile.professional_info.grade_id">
                            <b-th class="w-50" style="" scope="row">{{ $t('externalTraining.pay_grade') }}</b-th>
                            <b-td class="w-50" style="">
                              {{ ($i18n.locale=='bn') ? profile.grade_name_bn : profile.grade_name }}
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.controlling_authority || profile.professional_info.controlling_authority_bn">
                            <b-th class="w-50" style="" scope="row">{{ $t('elearning_iabm.designation_c') }}</b-th>
                            <b-td class="w-50" style="">
                              <slot>
                                {{ ($i18n.locale=='bn') ? profile.professional_info.controlling_authority_bn : profile.professional_info.controlling_authority }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.telephone">
                            <b-th class="w-50" style="" scope="row">{{ $t('elearning_iabm.telephone_c') }}</b-th>
                            <b-td class="w-50" style="">
                              <slot>
                                {{ profile.professional_info.telephone }}
                              </slot>
                            </b-td>
                          </b-tr>
                          <b-tr v-if="profile.professional_info.email">
                            <b-th class="w-50" style="" scope="row">{{ $t('elearning_iabm.email_c') }}</b-th>
                            <b-td class="w-50" style="">
                              <slot>
                                {{ profile.professional_info.email }}
                              </slot>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                </b-col>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import ExportPdf from './export-pdf_details_registration'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'

export default {
  components: {
    ListReportHead
  },
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.profile = this.item
    this.orgiId = 1
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.orgiId = this.$store.state.Auth.authUser.org_id
    }
  },
  data () {
    return {
      profile: [],
      slOffset: 1,
      orgiId: 0,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    getOrgName (id) {
      const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Participant Registration Details' : 'প্রশিক্ষণার্থী নিবন্ধনের বিবরণ'
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.profile, this)
    }
  }
}
</script>

<style>.hidden_header {
  display: none
}

.card-border {
  border: 1px solid #b9bdc1;
  box-shadow: 1px 1px 6px -1px grey;
  background-color: #dee2e6;
}

[dir=ltr][mode=light] .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px;
}

.reportTitle {
  font-weight: bold;
  border: 1px solid;
  padding: 8px 15px 8px 15px;
  border-radius: 11px;
}

.titleDiv {
  margin-top: 2rem;
  font-size: 18px;
  margin-bottom: 20px;
}

.report-name {
  font-weight: bold !important;
  text-transform: uppercase;
}

.my-btn {
  padding: 2px !important;
}</style>
